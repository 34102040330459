<template>

	<div class="main-contents">
		<div class="mem_container">
			<div class="mem_contents_header myp">
				<div v-if="input.projectDivCd == '1'" class="title-txt">일반 프로젝트 &gt; 진행단계별 프로젝트<br/>
					<p class="myp"><span>{{projectCountMap.projectTotalCount}}</span>개의 프로젝트가 있습니다!</p>
				</div>
				<div v-else class="title-txt">공급기업 지정 프로젝트 &gt; 진행단계별 프로젝트<br/>
					<p class="myp"><span>{{projectCountMap.projectTotalCount}}</span>개의 프로젝트가 있습니다!</p>
				</div>
			</div>
			<div v-if="unEvalCount > 0" class="myp-banner">
				<img src="/images/top_banner.png" alt="상단배너"/>
			</div>
			<div class="mem_info_contents">
				<div class="tab-type2">
					<ul v-if="input.projectDivCd == '1'" class="tab">
						<li @click="$router.push({name : 'PRJ205M01'})">
							모집중인 프로젝트
							 <!-- <span class="count">{{projectCountMap.projectRecCount}}건</span> -->
						</li>
						<li class="on">
							제안단계 프로젝트
							 <!-- <span class="count">{{projectCountMap.projectProCount}}건</span> -->
						</li>
						<li @click="$router.push({name : 'PRJ205M03'})">
							수행예정 프로젝트
							 <!-- <span class="count">{{projectCountMap.projectPreCount}}건</span> -->
						</li>
						<li @click="$router.push({name : 'PRJ205M04'})">
							수행중인 프로젝트
							 <!-- <span class="count">{{projectCountMap.projectIngCount}}건</span> -->
							<div v-if="projectCountMap.projectIngUnEvalCount > 0" class="alarm show">
								<img src="/images/tab_alarm.png" alt="미평가인재알람"/>
								<p>미평가 인재가 있습니다!</p>
							</div>
						</li>
						<li @click="$router.push({name : 'PRJ205M05'})">
							완료 프로젝트
							 <!-- <span class="count">{{projectCountMap.projectEndCount}}건</span> -->
							<div v-if="projectCountMap.projectEndUnEvalCount > 0" class="alarm show">
								<img src="/images/tab_alarm.png" alt="미평가인재알람"/>
								<p>미평가 인재가 있습니다!</p>
							</div>
						</li>
					</ul>
					<ul v-else class="tab">
						<li @click="$router.push({name : 'PRJ203M01'})">
							모집중인 프로젝트
							 <!-- <span class="count">{{projectCountMap.projectRecCount}}건</span> -->
						</li>
						<li class="on" >
							제안단계 프로젝트
							 <!-- <span class="count">{{projectCountMap.projectProCount}}건</span> -->
						</li>
						<li @click="$router.push({name : 'PRJ203M03'})">
							수행예정 프로젝트
							 <!-- <span class="count">{{projectCountMap.projectPreCount}}건</span> -->
						</li>
						<li @click="$router.push({name : 'PRJ203M04'})">
							수행중인 프로젝트
							 <!-- <span class="count">{{projectCountMap.projectIngCount}}건</span> -->
							<div v-if="projectCountMap.projectIngUnEvalCount > 0" class="alarm show">
								<img src="/images/tab_alarm.png" alt="미평가인재알람"/>
								<p>미평가 인재가 있습니다!</p>
							</div>
						</li>
						<li @click="$router.push({name : 'PRJ203M05'})">
							완료 프로젝트
							 <!-- <span class="count">{{projectCountMap.projectEndCount}}건</span> -->
							<div v-if="projectCountMap.projectEndUnEvalCount > 0" class="alarm show">
								<img src="/images/tab_alarm.png" alt="미평가인재알람"/>
								<p>미평가 인재가 있습니다!</p>
							</div>
						</li>
					</ul>
				</div>
				<div class="contents-body prj_mn">
					<div class="search-box">
						<div class="prj_tit">제안단계 프로젝트 <span class="prj_cnt">{{pageInfo.totalRecordCount}}건</span></div>
						<InputComp :class="input.projectDivCd == 3 ? 'sco_prj':''" type="text" placeholder="프로젝트명" v-model="input.searchKeyword" @keyup.enter="getList('1')"  ref="ref_search" />
						<InputComp v-if="input.projectDivCd == 3" class="sco_prj" type="text" placeholder="공급기업명" v-model="input.corpKeyword" @keyup.enter="getList('1')"  ref="ref_search" />
						<div class="search_btn" @click="getList('1')"><img class="search" src="/images/search_btn.png" alt="검색버튼"/></div>
					</div>
					<div class="filter">
						<div class="filter_box">
							<ul>
								<li><p class="text">NO.</p></li>
								<li><img :class="input.orderGubun == '1' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('1')">등록일 순</p><span>ㅣ</span></li>
								<li><img :class="input.orderGubun == '5' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('5')">개발기간 긴 순</p><span>ㅣ</span></li>
								<li><img :class="input.orderGubun == '4' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('4')">개발기간 짧은 순</p><span>ㅣ</span></li>
								<li><img :class="input.orderGubun == '2' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('2')">참여인재 많은 순</p><span>ㅣ</span></li>
								<li><img :class="input.orderGubun == '3' ? 'filter' : ''" src="/images/icon_filter.png" alt="필터"/><p @click="orderSearch('3')">참여인재 적은 순</p></li>
							</ul>
						</div>
					</div>
					
					<div v-for="(project, index) in projectList" :key="project.projectSeq" class="prj_mn-box">
						<div class="no">
							{{ (pageInfo.pageIndex - 1) * pageInfo.pageUnit + (index + 1)}}
						</div>
						<div class="view">
							<div class="req box-hover">
								<div class="top">									
									<div class="state_mark us mr-2-5">제안단계</div>
									<div v-if="project.projectStatus == '8'" class="state_mark rq mr-2-5">미 승 인</div>
									<div class="proc" v-else-if="project.recrEndDateDiff >= 0">+모집중</div>
									<div class="new" v-if="project.newYn == 'Y'">NEW</div>
									<div class="right">
										<div class="date">등록일자 {{project.regYyyymmdd}}</div>
									</div>
								</div>								
								
								<!--class _new add-->
								<div class="itv-outline int_prj_new">
								
									<div class="regi pb-0">
										<div v-if="input.projectDivCd == '1'" class="tit int_prj">프로젝트명</div>
										<div v-else class="tit int_prj flog">공급기업 지정</div>

										<div class="name prj-tit-hover" style="width: 540px;" @click="clickProjectInfo(project.projectSeq, project.projectDivCd)">{{project.projectNm}}</div>
										<div class="int_box">
											<div class="list-view int_prj">
												<div class="label ivl03">개요</div>
												<div class="list text">{{project.projectDtlDesc}}</div>
											</div>
											<div class="list-view int_prj">
												<div class="label ivl04">고객사</div>
												<div class="list">
													<div class="add">{{project.clientNm}}</div>
													<div class="loca"><img src="/images/location.png" alt="위치" />{{project.projectLocCont}}</div>
												</div>
											</div>
											<div class="list-view int_prj">
												<div class="label ivl05">산업분야</div>
												<div class="list" style="display: inline-block !important;"><SelectComp type="text" cdId="PRO105" :value="project.indtypeClassCd.substr(0,1)"/> / <SelectComp type="text" cdId="PRO143" :value="project.indtypeClassCd"/></div>
											</div>
										</div>
									</div>

									<div class="int_box">                                            
										<div class="list-view int_prj">
											<div v-if="input.projectDivCd == 3" class="label ivl04">공급사</div>
											<div v-else class="label ivl04">수행사</div>
											<div class="list text">{{project.corpNm}}</div>
										</div>
										<div class="list-view int_prj">
											<div class="label ivl05">개발기간</div>
											<div class="list">{{project.projectStartEndYyyymmdd}}</div>
										</div>
										<div class="list-view int_prj">
											<div class="label ivl05">직무분야</div>
											<div class="list">
												<ul class="duty-list">
													<li v-for="(duty, index) in project.dutyList" :key="index">
														<span>
															<SelectComp type="text_comma_split" cdId="PRO141" :value="duty.dutyFilterCd"/>/<SelectComp type="text_comma_split" cdId="PRO142" :value="duty.dutyFieldCd"/>
														</span>
													</li>
												</ul>
											</div>
										</div>
										<div class="list-view int_prj">
											<div class="label ivl05">개발기술</div>
											<div class="list text">{{project.devTechCont}}</div>
										</div>                                          
									</div>                                        
								</div>

								<!--고객CI/참여인재정보-->
								<div class="client_info">
									<div class="ci_box">
										<ImageComp type="image" :src="'/api/prj/tec/projectCorpImg?projectSeq='+project.projectSeq" defaultSrc="COMPANY" alt="고객사 로고"/>
									</div>
									<div class="human_count">
										참여인재 <span class="count">{{project.mberCnt}}</span>명
										<img src="/images/pt_member.png" alt="참여인재수"/>
										<!-- <img class="fold" src="/images/fold_down_btn.png" alt="버튼"/> -->
										<div class="fold_btn">
											<!-- <img v-if="project.mberCnt > 0 && projectSeqTecMber != project.projectSeq" class="fold" src="/images/fold_down_btn.png" alt="버튼" @click="openTecMber(project)"/>
											<img v-if="project.mberCnt > 0 && projectSeqTecMber == project.projectSeq" class="fold" src="/images/fold_top_btn.png" alt="버튼" @click="closeTecMber()"/> -->
											<img v-if="project.mberCnt > 0" class="fold" src="/images/fold_down_btn.png" alt="버튼" @click="clickProjectTecMberList(project)"/>
										</div>
									</div>
								</div>

							</div>
						</div>

						<!-- 참여인재 -->
						<div v-if="projectSeqTecMber == project.projectSeq && projectTecMberList.length > 0" 
								class="Board type3 itv show dco">					   
							<table class="Board_type3 dco">
								<colgroup>
									<col width="3%">
									<col width="3%">
									<col width="5%">
									<col width="6%">
									<col width="3%">
									<col width="3%">
									<col width="6%">
									<col width="16%">
									<col width="10%">
									<col width="15%">
									<!-- <col width="7%"> -->
								</colgroup>
								<thead>
									<tr>
										<th><input type="checkbox" style="width:14px" @click="allCheck()" v-model="checkBox"></th>
										<th>NO</th>
										<th>평균<br/>평가등급</th>
										<th>성명</th>
										<th>성별</th>
										<th>년생</th>
										<th>투입등급</th>
										<th>참여소속사</th>
										<th>담당분야</th>
										<th>참여기간</th>
										<!-- <th>참여상태</th> -->
									</tr>
								</thead>
								<tbody>
									<tr v-for="(mber, index) in projectTecMberList" :key="mber.mberSeq">
										<td class="score checkbox dco">
											<SelectComp type="checkbox" v-model="selectedIndex" :list="index+ ':'" @change="check()"/>
										</td>
										<td class="score">{{index + 1}}</td>
										<td class="score">
											<grade-img type="TOT_EVAL_ADM" :grade="mber.totEvalGradeCd " :specialYn="mber.specialGradeYn">
												<template v-slot:smark><div class="s-mark"><img src="/images/admin/s-mark.png" alt="스페셜마크"></div></template>
											</grade-img>
										</td>
										<td class="score name">{{mber.mberNm}}</td>
										<td class="score">{{mber.gender | gender}}</td>
										<td class="score">{{mber.birthYyyymmdd | birthYear}}</td>
										
										<td class="score"><grade-img type="LICENSE_TECH_DCO" :grade="mber.projectJoinTechGradeCd"/></td>
										<td class="score">
											<span v-if="mber.corpNm != undefined">{{mber.corpNm}}</span>
											<span v-else class="color-gray">소속없음</span>
										</td>
										<!-- <td class="score"><SelectComp type="text_comma_split" cdId="PRO142" :value="mber.dutyCdList"/></td> -->
										<td class="score">{{mber.workCont}}</td>
										<td class="score">{{mber.joinStartEndYyyymmdd}}</td>
										<!-- <td class="score resume"><div class="btn" @click="changeJoinStartEndYyyymmdd(project, mber.mberSeq)">참여기간 변경 &#9654;</div></td> -->
										<!-- <td class="score">
											<div class="state pf_ep"/>
											<SelectComp type="text" list="0:중도철수,1:참 여 중,2:참여예정,9:참여완료" :value="mber.projectRecrStatus"/>
										</td> -->
									</tr>						
								</tbody>
							</table>
							<div class="humaninfo-box">
								<div class="btn-humaninfo" @click="mberInfoDown(project)">
									<img src="/images/human_info_down_btn_blue.png"/>
									<p>선택한 기술인재 이력서 다운로드</p>
								</div>
							</div>
						</div>
					</div>

					<div v-if="projectList.length == 0" class="project_box prj">
						<div class="prj_pf none">
							프로젝트가 없습니다!
						</div>
					</div>
				</div>
				
				<!--페이징 컴포넌트 -->
				<PagingComp :pageInfo="pageInfo" @page-click="goPage"/>
				<!--// 페이징 컴포넌트 -->
			</div>
		</div>
	</div>

</template>

<script>

import PagingComp from '@/components/PagingComp.vue';
import gradeImg from "@/components/highpro/GradeImg.vue";

export default {

	components : {
		PagingComp,
		gradeImg
	},

	data() {
		return {
			input: {
				pageIndex : '1',
				
				searchGubun : '1',		// 검색은 프로젝트명으로만
				projectStatus : ['4'],	// (배열로..) 프로젝트 상태 1:수행중, 2:수행예정, 9:완료
				orderGubun : '1',		// 1:등록일 순, 2:참여인재 많은 순, 3:참여인재 적은 순, 4:개발기간 짧은 순, 5:개발기간 긴 순
				

			}, // 검색조건		

			nowYyyymmdd : '',

			projectList : [],
			projectTecMberList : [],
			pageInfo : {},
			projectCountMap : {},
			unEvalCount : 0,
			projectSeqTecMber : '',
			selectedIndex : [],

			checkBox : false,
		};
	},
	bforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');
		// this.input.projectGubun = this.$route.name.substr(5,1) == '7' ? '3' : '1';
		// this.input.searchGubun = this.$route.name.substr(5,1) == '3' ? '5' : '1';
		this.input.projectDivCd = this.$route.name.substr(5,1) == '3' ? '3' : '1';

		if(this.$route.params) {
			this.input.searchKeyword = this.$route.params.srchName
			if(this.$route.params.corpName) {
				this.input.corpKeyword = this.$route.params.corpName;
			}
		}
		
	},
	mounted() {
		// console.log('mounted');

		if(this.$route.params != undefined && this.$route.params.pageIndex != undefined) {			
			this.input = this.$route.params;
		}
		// console.log("gubtn : " + this.projectGubun);
		this.nowYyyymmdd = new Date().format("yyyyMMdd");
		this.getList();
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {
		getList(div, isScroll = false) {
			if(div) this.input.pageIndex = 1;

			this.$.httpPost('/api/prj/dco/gmg/getTotalProjectList', this.input)
				.then(res => {
					//console.log('getMyProjectList RESULT', res.data);

					this.projectList 		= res.data.list;
					this.pageInfo 			= res.data.pageInfo;
					this.projectCountMap	= res.data.projectCountMap;
					this.unEvalCount		= res.data.projectCountMap.projectIngUnEvalCount + res.data.projectCountMap.projectEndUnEvalCount;

					for(var i in this.projectList){
						
						if(this.projectList[i].dutyFieldCont){
							var tempArr = this.projectList[i].dutyFieldCont.split(',');
							this.projectList[i].dutyList = [];

							for(var j in tempArr){
								this.projectList[i].dutyList.push({dutyFilterCd : tempArr[j].substr(0,2),
																   dutyFieldCd  : tempArr[j]});
							}
						}

					}

					this.projectSeqTecMber = '';

					// if(this.projectList.length > 0 && this.projectList[0].mberCnt > 0) {
					// 	// 참여인재 조회
					// 	this.projectList[0].isOpenTecMber = true;
					// 	this.projectSeqTecMber = this.projectList[0].projectSeq;
					// 	this.getTecMberList(this.projectSeqTecMber);
					// }

					if(isScroll) {
						window.scroll(0, 0);
					}
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		},

		goPage(page) {
			// console.log(page);
			this.input.pageIndex = page;
			this.getList('', true);
		},

		orderSearch(orderGubun) {
			this.input.orderGubun = orderGubun;
			this.input.pageIndex = 1;
			this.getList();
		},		

		// 프로젝트 수정
		// goModifyProject(projectSeq) {
		// 	// this.$router.push({name : 'PRJ303M02', params : {projectSeq : projectSeq, caller : { name : 'PRJ302M02', params :this.input}}});
		// 	this.$router.push({name : 'PRJ303M02', query : {projectSeq : projectSeq}, params : {caller : { name : 'PRJ302M02', params :this.input}}});
		// },

		// 지원인재 보기
		// goAppMber(projectSeq, projectNm) {
        //     this.$router.push({name : 'PRJ305M01', query : {projectSeq : projectSeq}, params : { srchName : projectNm, caller : { name : 'PRJ302M02', params :this.input}}});
		// },

		clickProjectInfo(projectSeq, projectDivCd) {			
			// this.$router.push({name : 'PRJ303M01', query : {projectSeq : projectSeq}, params : {div : projectDivCd, caller : { name : 'PRJ302M02', params :this.input}}});
			this.$router.push({name : 'PRJ201M02', query : {projectSeq : projectSeq, page : projectDivCd == "32" || projectDivCd == "31" ? 1 : 0}, params : { caller : {name : this.$route.name, params :this.input}}});
		},

		getTecMberList(projectSeq) {
			this.checkBox = false;
			this.selectedIndex = [];
			var input = {
				projectSeq : projectSeq,
				projectDivCd : this.input.projectDivCd
			};

			this.$.httpPost('/api/prj/sco/choice/getProjectTecMberList', input)
				.then(res => {
					// console.log(res.data);
					this.projectTecMberList	= res.data.list;

					this.projectSeqTecMber = projectSeq;

				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});
		},

		// openTecMber(project) {
		// 	// this.checkBox = false;
		// 	// this.selectedIndex = [];
		// 	this.getTecMberList(project.projectSeq);
		// },

		// closeTecMber() {	
		// 	this.projectSeqTecMber = '';		
		// 	// this.$forceUpdate();
		// },	


		// allCheck() {
		// 	if(this.checkBox){	
		// 		this.selectedIndex = [];
		// 	} else {
		// 		this.selectedIndex = [];
		// 		for(var i = 0; i < this.projectTecMberList.length; i++)
		// 		this.selectedIndex.push(String(i));
		// 	}
		// 	// console.log("allCheck : " + this.selectedIndex);
		// },

		// check() {
		// 	if(this.checkBox && this.selectedIndex.length != this.projectTecMberList.length) {
		// 		this.checkBox = false;
		// 	}

		// 	// console.log("Check : " + this.selectedIndex);
		// },

		clickProjectTecMberList(project) {
			this.$router.push({name : 'PRJ203M11', params : { project : project, input : this.input ,  caller : {name : this.$route.name, params :project} }});
		},

		// clickTecMber(mberSeq){
		// 	this.$router.push({name: 'SRH301M01', params : {mberSeq : mberSeq}})
        // },

		// mberInfoDown(project) {

		// 	var downloadMberList = [];
			
		// 	for(var i of this.selectedIndex) {
		// 		downloadMberList.push(this.projectTecMberList[i]);
		// 	}

		// 	if(downloadMberList.length < 1) {
		// 		alert("기술인재가 선택되지 않았습니다.");
		// 		return;
		// 	}

		// 	var param = {
		// 		projectSeq : project.projectSeq,
		// 		projectNm : project.projectNm,
		// 		mberList : downloadMberList,
		// 		projectDivCd : project.projectDivCd
		// 	}

		// 	this.$.fileDownload ('/api/prj/dco/pmg/getPrjMberFileDown', param)
        //         .then(() => {
        //             // console.log("res : " + res);
		// 		})
        //         .catch(err => {
		// 			// console.log('============================='); 
		// 			// console.log(err);
		// 			// console.log(err.response);
		// 			// console.log('============================='); 

		// 			alert(err.response.data.error_description);
		// 		});
		// },

	},
};
</script>
